import React, { useState, useEffect, useCallback, useRef } from 'react';
import { toast } from 'react-toastify';
import { Base_url } from '../../../utils/Base_url';
import axios from 'axios';
import Input from '../../../components/Input';
import Button from '../../../components/Button';
import Modal from '../../../components/modal';
import Datepicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { MdClose, MdOutlineFileUpload } from 'react-icons/md';
import { setSuccess } from '../../../store/reducers/globalReducer';
import { useForm } from '../../../hooks/Form';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useCreateMutation } from '../../../store/services/companyExpenseService';
import moment from 'moment';
const EditCompanyExpense = ({ isModalOpen, setIsModalOpen, closeModal, setUsers, getData }) => {
  const [startDate, setStartDate] = useState('');

  const handleDateChange = (date) => {
    setStartDate(date);
    console.log(date);
  };

  useEffect(() => {
    console.log(moment(startDate).format('YYYY-MM-DD'));
  }, [startDate]);

  const [loading, setLaoder] = useState(false);
  const [value, setValue] = useState('');
  const [selectedImage, setSelectedImage] = useState(null);

  const [selectedImages, setSelectedImages] = useState(null);

  const fileInputRef = useRef(null);

  const handleDragOver = useCallback((e) => {
    e.preventDefault();
  }, []);

  const handleDrop = useCallback((e) => {
    e.preventDefault();
    const file = processFile(e.dataTransfer.files[0]);
    // uploadImage(file);
    setSelectedImages(file);
  }, []);

  const handleFileChange = (e) => {
    processFile(e.target.files[0]);
    // uploadImage(e.target.files[0]);
    setSelectedImages(e.target.files[0]);
  };

  const processFile = (file) => {
    // uploadImage(file);
    setSelectedImages(file);
    if (file && file.type.startsWith('image/')) {
      const reader = new FileReader();
      reader.onload = () => {
        setSelectedImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const triggerFileInput = () => {
    fileInputRef.current.click();
  };

  const { state, onChange } = useForm({
    expenseType: '',
    receiptNumber: '',
    amount: ''
  });

  console.log(state);

  const [create, response] = useCreateMutation();
  const onSubmit = async () => {
    setLaoder(true);
    try {
      let profilephoto = '';
      if (selectedImages) {
        const formData = new FormData();
        formData.append('images', selectedImages);
        const uploadResponse = await axios.post(
          'https://file-upload-ashen.vercel.app/api/upload',
          formData
        );
        profilephoto = uploadResponse.data.data[0];
      }

      const params = {
        date: moment(startDate).format('YYYY-MM-DD'),
        expenseType: state.expenseType || getData?.expenseType,
        receiptNumber: state.receiptNumber || getData?.receiptNumber,
        amount: Number(state.amount) || getData?.amount,
        image: profilephoto || getData?.image
      };

      const response = await axios.patch(
        `https://welcome-habibi-backend.vercel.app/v1/manager/update-company-expense/${getData?._id}`,
        params
      );

      if (response.data.success) {
        toast.success('Company expense updated successfully!');
        setIsModalOpen(false);
        window.location.reload();
      }
    } catch (error) {
      console.error(error);
      toast.error('Failed to update expense!');
    } finally {
      setLaoder(false);
    }
  };

  useEffect(() => {
    if (response.isError) {
      // setErrors(response?.error?.data?.message);
      toast.error(response?.error?.data?.message);
    }
  }, [response?.error?.data]);
  console.log(response);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    if (response.isSuccess) {
      if (response?.data?.success === true) {
        dispatch(setSuccess(response?.data?.message));
        toast.success(response?.data?.message);
        setLaoder(false);
        window.location.reload();
        setIsModalOpen(false);
      } else {
        toast.success(response?.data?.message);
        setLaoder(false);
      }
    }
  }, [response.isSuccess]);

  const handleValueChange = (newValue) => {
    console.log('newValue:', newValue);
    setValue(newValue);
  };

  return (
    <div>
      <Modal
        isOpen={isModalOpen}
        onClose={closeModal}
        className={' md:w-[80%] md:pb-14 pb-0 w-full'}
      >
        <div className="">
          <div className=" p-5">
            <div className="  flex justify-between items-center">
              <div></div>

              <MdClose
                className="  text-gray-500"
                onClick={() => setIsModalOpen(false)}
                size={25}
              />
            </div>

            <form
              onSubmit={(e) => {
                e.preventDefault();
                onSubmit(e.target);
              }}
            >
              <div className=" md:flex block justify-between">
                <div className=" md:w-[45%] w-[100%] flex gap-3  flex-col">
                  <div className=" w-full flex flex-col">
                    <label className=" pb-3 text-lg  font-medium">Deposit Date</label>

                    <div>
                      <div className=" relative">
                        <Datepicker
                          selected={startDate}
                          onChange={handleDateChange}
                          // minDate={new Date()}
                          className="bg-lightGray w-full  rounded-md p-4 "
                          defaultValue={getData?.date}
                        />
                        <div className=" absolute right-4 top-4">
                          <img src={require('../../../assets/image/Calendar.png')} />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className=" w-full">
                    <label
                      htmlFor="first_name"
                      className=" block mb-2 text-[18px]  font-medium text-black"
                    >
                      Expense Type
                    </label>
                    <select
                      defaultValue={getData?.expenseType}
                      name="expenseType"
                      className=" py-4 font-medium w-full rounded border-r-8   bg-lightGray outline-none  border-transparent px-4 text-md"
                    >
                      {/* <option>Select Type of Expense</option> */}
                      <option value={'Utility Bills'}>Utility Bills</option>
                      <option value={'Furniture '}>Furniture</option>
                      <option value={'Stationary Items'}>Stationary Items</option>
                      <option value={'Government fee'}>Government fee</option>
                      <option value={'Zakat'}>Zakat</option>
                      <option value={'Donations'}>Donations</option>
                      <option value={'Others'}>Others</option>
                    </select>
                  </div>

                  <div className=" w-full">
                    <Input
                      onChange={onChange}
                      // value={state.receiptNumber}
                      name={'receiptNumber'}
                      label={'Receipt No'}
                      placeholder={''}
                      className={' w-full  py-4'}
                      defaultValue={getData?.receiptNumber}
                    />
                  </div>
                </div>
                <div className=" flex flex-col gap-3 md:w-[40%] w-full">
                  <div className=" w-full">
                    <Input
                      Icon={''}
                      onChange={onChange}
                      // value={state.amount}
                      type="number"
                      label={' Amount'}
                      placeholder={''}
                      name={'amount'}
                      className={' w-full  py-4'}
                      defaultValue={getData?.amount}
                    />
                  </div>
                  <div
                    className="w-full border-dashed border-[#9E9E9E] border-2 rounded-lg p-4 cursor-pointer"
                    onDragOver={handleDragOver}
                    onDrop={handleDrop}
                    onClick={triggerFileInput} // Allow clicking anywhere in the div to trigger file input
                  >
                    <div className="bg-[#F6F6F6] mx-auto w-14 flex justify-center items-center h-14 rounded-2xl">
                      <MdOutlineFileUpload size={30} color="#c8c7cf" />
                    </div>
                    <p className="text-primary font-medium mt-3 text-center">
                      <span className="text-secondary">Click here</span> or drag and drop to upload
                      a slip
                    </p>
                    {!selectedImage ? (
                      <img
                        src={getData?.image}
                        alt="Uploaded slip"
                        className="mt-4 mx-auto"
                        style={{ maxWidth: '100%', height: 'auto' }}
                      />
                    ) : (
                      selectedImage && (
                        <img
                          src={selectedImage}
                          alt="Uploaded slip"
                          className="mt-4 mx-auto"
                          style={{ maxWidth: '100%', height: 'auto' }}
                        />
                      )
                    )}
                    <input
                      type="file"
                      onChange={handleFileChange}
                      style={{ display: 'none' }}
                      ref={fileInputRef}
                    />
                  </div>

                  {loading === true ? (
                    <button
                      disabled
                      type="button"
                      className="  bg-blue text-center  mt-3 justify-center uppercase text-white py-3 border-b-black  border-2 w-full"
                    >
                      <svg
                        aria-hidden="true"
                        role="status"
                        className="inline w-4 h-4 me-3 text-white animate-spin"
                        viewBox="0 0 100 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                          fill="#E5E7EB"
                        />
                        <path
                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                          fill="currentColor"
                        />
                      </svg>
                      Loading...
                    </button>
                  ) : (
                    <Button
                      label={'Update'}
                      type={'submit'}
                      className={
                        '       bg-blue text-center  mt-3 justify-center uppercase text-white py-3 border-b-black  border-2 w-full'
                      }
                    />
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default EditCompanyExpense;
