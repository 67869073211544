import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';
import { MdClose } from 'react-icons/md';
import Modal from '../../../components/modal';
import Input from '../../../components/Input';
import Button from '../../../components/Button';
import { Base_url } from '../../../utils/Base_url';
import Datepicker from 'react-tailwindcss-datepicker';
import { MdOutlineFileUpload } from 'react-icons/md';
import { useUploadImageMutation } from '../../../store/services/uploadService';
import { useForm } from '../../../hooks/Form';
import { useCreateMutation } from '../../../store/services/investorService';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setSuccess } from '../../../store/reducers/globalReducer';
import moment from 'moment';

const Add_ViewForm = ({
  isModalOpen,
  setIsModalOpen,
  closeModal,
  setUsers,
  formData,
  names,
  stateData,
  getData,
  values,
  workerId
}) => {
  console.log(formData);
  console.log(stateData);
  console.log(values);

  console.log(getData, 'fdsgfs');

  const [uploadImage, { isLoading, data, error }] = useUploadImageMutation();

  console.log(data);

  const [Loading, setLoader] = useState(false);
  const { workerToken } = useSelector((state) => state.authReducer);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);

  const [state, setState] = useState({
    name: '',
    fatherName: '',
    permanentAddress: '',
    temporaryAddress: '',
    dateOfBirth: '',
    nationality: '',
    maritalStatus: '',
    cnic: '',
    profession: '',
    phoneNumber: '',
    whatsappNumber: '',
    otherNumber: ''
  });

  console.log(state);

  const [guardian, setGuardian] = useState({
    name: '',
    fatherName: '',
    relationship: '',
    permanentAddress: '',
    cnic: '',
    phoneNumber: '',
    whatsappNumber: ''
  });

  console.log(guardian);

  const worker_data = JSON.parse(localStorage.getItem('worker_data'));

  const onChangeGuardian = (e) => {
    const { name, value } = e.target;
    setGuardian((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    const isPatternCorrect = /^\d{13}$/.test(state.cnic.replace(/[-\s]/g, ''));
    const isGuardianCnicCorrect = /^\d{13}$/.test(guardian.cnic.replace(/[-\s]/g, ''));

    const dateRegex = /^(0[1-9]|[12][0-9]|3[01])-(0[1-9]|1[0-2])-(\d{4})$/;

    if (!dateRegex.test(state?.dateOfBirth)) {
      toast.error('Invalid date! Please use DD-MM-YYYY with a valid day, month, and year.');
      return;
    }

    if (state.name.length === 0) {
      toast.error('Please enter name!');
    } else if (state.fatherName.length === 0) {
      toast.error('Please enter father name!');
    } else if (state.permanentAddress.length === 0) {
      toast.error('Please enter permanent address!');
    } else if (state.temporaryAddress.length === 0) {
      toast.error('Please enter temporary address!');
    } else if (state.dateOfBirth.length === 0) {
      toast.error('Please enter dateOfBirth!');
    } else if (state.birthPlace.length === 0) {
      toast.error('Please enter birthPlace!');
    } else if (state.cast.length === 0) {
      toast.error('Please enter cast!');
    } else if (state.nationality.length === 0) {
      toast.error('Please enter nationality!');
    } else if (state.maritalStatus.length === 0) {
      toast.error('Please enter maritalStatus!');
    } else if (state.cnic.length === 0) {
      toast.error('Please enter cnic!');
    } else if (!isPatternCorrect) {
      toast.error("Please make sure it's exactly 13 digits");
    } else if (state.profession.length === 0) {
      toast.error('Please enter profession!');
    } else if (state.phoneNumber.length === 0) {
      toast.error('Please enter phone number!');
    } else if (state.whatsappNumber.length === 0) {
      toast.error('Please enter  whatsapp number!');
    } else if (guardian.name.length === 0) {
      toast.error('Please enter guardian name!');
    } else if (guardian.fatherName.length === 0) {
      toast.error('Please enter guardian father name!');
    } else if (guardian.relationship.length === 0) {
      toast.error('Please enter relationship!');
    } else if (guardian.permanentAddress.length === 0) {
      toast.error('Please enter guardian permanent address!');
    } else if (guardian.temporaryAddress.length === 0) {
      toast.error('Please enter guardian temporary address!');
    } else if (guardian.cnic.length === 0) {
      toast.error('Please enter guardian cnic!');
    } else if (guardian.phoneNumber.length === 0) {
      toast.error('Please enter  guardian phone number!');
    } else if (guardian.whatsappNumber.length === 0) {
      toast.error('Please enter   guardian whatsapp number!');
    } else if (isCheckboxChecked === false) {
      toast.error('Please check permission letter');
    } else {
      setLoader(true);

      const params = {
        investorId: getData?._id,
        name: state.name,
        fatherName: state.fatherName,
        birthPlace: state.birthPlace,
        cast: state.cast,
        // jobDetail: state.jobDetail,
        permanentAddress: state.permanentAddress,
        temporaryAddress: state.temporaryAddress,
        dateOfBirth: moment(state.dateOfBirth, 'DD-MM-YYYY', true).isValid()
          ? moment(state.dateOfBirth, 'DD-MM-YYYY').toISOString() // Convert to ISO format
          : null,
        nationality: state.nationality,
        maritalStatus: state.maritalStatus,
        cnic: state.cnic.replace(/[-\s]/g, ''),
        profession: state.profession,
        phoneNumber: state.phoneNumber,
        whatsappNumber: state.whatsappNumber,
        guardian: {
          name: guardian.name,
          fatherName: guardian.fatherName,
          relationship: guardian.relationship,
          permanentAddress: guardian.permanentAddress,
          temporaryAddress: guardian.permanentAddress,
          cnic: guardian.cnic.replace(/[-\s]/g, ''),
          phoneNumber: guardian.phoneNumber,
          whatsappNumber: guardian.whatsappNumber
        }
      };

      console.log(params);

      await axios
        .post(`https://welcome-habibi-backend.vercel.app/v1/worker/bio-investor`, params)
        .then(async (res) => {
          console.log(res);

          if (res.data.success === true) {
            setIsModalOpen(false);
            toast.success(res.data.message);
            setLoader(false);
            window.location.reload();
          } else {
            toast.error(res.data.message);
            toast.error(res);
            setLoader(false);
          }
        })
        .catch((error) => {
          console.log(error);

          toast.error(error?.response?.data?.message);
          toast.error(error);
          setLoader(false);
        });
    }
  };

  const [value, setValue] = useState('');

  const handleValueChange = (newValue) => {
    console.log('newValue:', newValue);
    setValue(newValue);
  };

  return (
    <div>
      <Modal isOpen={isModalOpen} onClose={closeModal} className={' md:w-[80%] w-[100%]'}>
        <div className="">
          <div className=" p-3 flex justify-between items-center">
            <div></div>

            {/* <MdClose
              className="  text-gray-500"
              onClick={() => setIsModalOpen(false)}
              size={25}
            /> */}
            <div></div>
          </div>

          <div className=" p-5">
            <form onSubmit={onSubmit}>
              <div className=" md:flex block justify-between">
                <div className=" md:w-[45%] w-[100%] flex gap-3  flex-col">
                  <div>
                    <p className="  text-primary font-bold text-xl">Investor Details</p>
                  </div>

                  <div className=" w-full">
                    <Input
                      onChange={onChange}
                      label={'Name'}
                      value={state.name}
                      placeholder={'Enter name..'}
                      name={'name'}
                      className={' w-full  py-4'}
                    />
                  </div>

                  <div className=" w-full">
                    <Input
                      label={'Father Name'}
                      onChange={onChange}
                      value={state.fatherName}
                      placeholder={'Enter Father Name..'}
                      name={'fatherName'}
                      className={' w-full  py-4'}
                    />
                  </div>

                  <div className=" w-full">
                    <Input
                      onChange={onChange}
                      value={state.permanentAddress}
                      label={'Permanent Address'}
                      placeholder={'Enter Permanent Address..'}
                      name={'permanentAddress'}
                      className={' w-full  py-4'}
                    />
                  </div>

                  <div className=" w-full">
                    <Input
                      onChange={onChange}
                      value={state.temporaryAddress}
                      label={'Temporary Address'}
                      placeholder={'Enter Temporary Address..'}
                      name={'temporaryAddress'}
                      className={' w-full  py-4'}
                    />
                  </div>
                  <div className=" w-full">
                    <Input
                      onChange={onChange}
                      value={state.dateOfBirth}
                      label={'Date of Birth'}
                      type={'text'}
                      placeholder={'DD-MM-YYYY'}
                      name={'dateOfBirth'}
                      className={' w-full  py-4'}
                    />
                  </div>

                  <div className=" w-full">
                    <Input
                      onChange={onChange}
                      value={state.birthPlace}
                      label={'BirthPlace'}
                      type={'text'}
                      placeholder={'Enter Birth place'}
                      name={'birthPlace'}
                      className={' w-full  py-4'}
                    />
                  </div>
                  <div className=" w-full">
                    <Input
                      onChange={onChange}
                      value={state.cast}
                      label={'Cast'}
                      type={'text'}
                      placeholder={'Enter cast'}
                      name={'cast'}
                      className={' w-full  py-4'}
                    />
                  </div>

                  {/* <div className=" w-full">
                    <Input
                      onChange={onChange}
                      value={state.jobDetail}
                      label={"job Detail"}
                      type={"text"}
                      placeholder={"Enter  Job.."}
                      name={"jobDetail"}
                      className={" w-full  py-4"}
                    />
                  </div> */}

                  <div className=" w-full">
                    <Input
                      onChange={onChange}
                      value={state.nationality}
                      label={'Nationality'}
                      type={'text'}
                      placeholder={'Enter Temporary Address..'}
                      name={'nationality'}
                      className={' w-full  py-4'}
                    />
                  </div>

                  <div className=" w-full">
                    <label
                      htmlFor="first_name"
                      className=" block mb-2 text-[18px]  font-medium text-black"
                    >
                      Marital
                    </label>
                    <select
                      onChange={onChange}
                      value={state.maritalStatus}
                      name="maritalStatus"
                      className=" py-4 font-medium w-full rounded border-r-8   bg-lightGray outline-none  border-transparent px-4 text-md"
                    >
                      <option>Select</option>
                      <option value={'Single'}>Single</option>
                      <option value={'Married'}>Married</option>
                      <option value={'Divorced'}>Divorced</option>
                      <option value={'Widowed'}>Widowed</option>
                    </select>
                  </div>

                  <div className=" w-full">
                    <Input
                      onChange={onChange}
                      value={state.cnic}
                      label={'National CNIC No'}
                      placeholder={' Enter National CNIC No'}
                      name={'cnic'}
                      className={' w-full  py-4'}
                    />
                  </div>

                  <div className=" w-full">
                    <Input
                      onChange={onChange}
                      value={state.profession}
                      label={'Profession'}
                      placeholder={' Enter Profession'}
                      name={'profession'}
                      className={' w-full  py-4'}
                    />
                  </div>
                  <div className=" w-full">
                    <Input
                      onChange={onChange}
                      value={state.phoneNumber}
                      name={'phoneNumber'}
                      label={'Personal Mobile Number'}
                      placeholder={' Enter Personal Mobile Number'}
                      className={' w-full  py-4'}
                    />
                  </div>
                  <div className=" w-full">
                    <Input
                      onChange={onChange}
                      value={state.whatsappNumber}
                      name={'whatsappNumber'}
                      label={'Whatsapp Number'}
                      placeholder={' Enter Whatsapp Number'}
                      className={' w-full  py-4'}
                    />
                  </div>
                  {/* <div className=" w-full">
                    <Input
                      onChange={onChange}
                      value={state.otherNumber}
                      name={"otherNumber"}
                      label={"Other Mobile Number"}
                      placeholder={" Enter Personal Mobile Number"}
                      className={" w-full  py-4"}
                    />
                  </div> */}
                </div>
                <div className=" flex flex-col gap-3 md:w-[40%] w-full">
                  <div>
                    <p className="  text-primary font-bold text-xl">Guardian (Ameen) Details</p>
                  </div>

                  <div className=" w-full">
                    <Input
                      label={'Name'}
                      onChange={onChangeGuardian}
                      value={guardian.name}
                      placeholder={'Enter  Name..'}
                      name={'name'}
                      className={' w-full  py-4'}
                    />
                  </div>
                  <div className=" w-full">
                    <Input
                      label={'Father Name'}
                      onChange={onChangeGuardian}
                      value={guardian.fatherName}
                      placeholder={'Enter  Father Name..'}
                      name={'fatherName'}
                      className={' w-full  py-4'}
                    />
                  </div>

                  <div className=" w-full">
                    <Input
                      onChange={onChangeGuardian}
                      value={guardian.cnic}
                      label={'National CNIC No'}
                      placeholder={'Enter CNIC..'}
                      name={'cnic'}
                      className={' w-full  py-4'}
                    />
                  </div>

                  <div className=" w-full">
                    <Input
                      onChange={onChangeGuardian}
                      value={guardian.permanentAddress}
                      label={'Permanent Address'}
                      placeholder={'Enter Permanent Address..'}
                      name={'permanentAddress'}
                      className={' w-full  py-4'}
                    />
                  </div>
                  <div className=" w-full">
                    <Input
                      onChange={onChangeGuardian}
                      value={guardian.temporaryAddress}
                      label={'Temporary Address'}
                      placeholder={'Enter Temporary Address..'}
                      name={'temporaryAddress'}
                      className={' w-full  py-4'}
                    />
                  </div>

                  <div className=" w-full">
                    <Input
                      onChange={onChangeGuardian}
                      label={'Relationship With Invester'}
                      value={guardian.relationship}
                      placeholder={'Enter Relationship With Invester'}
                      name={'relationship'}
                      className={' w-full  py-4'}
                    />
                  </div>

                  <div className=" w-full">
                    <Input
                      onChange={onChangeGuardian}
                      value={guardian.phoneNumber}
                      name={'phoneNumber'}
                      label={'Personal Mobile Number'}
                      placeholder={' Enter Personal Mobile Number'}
                      className={' w-full  py-4'}
                    />
                  </div>
                  <div className=" w-full">
                    <Input
                      onChange={onChangeGuardian}
                      value={guardian.whatsappNumber}
                      name={'whatsappNumber'}
                      label={'Whatsapp Number'}
                      placeholder={' Enter Whatsapp Number'}
                      className={' w-full  py-4'}
                    />
                  </div>
                </div>
              </div>

              <div className=" py-8 text-left w-full">
                <p className=" text-black font-semibold">PERMISSION LETTER</p>
                <p className=" text-sm text-black">
                  The department wants permission to buy and sell all kinds of goods, whether for
                  cash or credit, to appoint someone as an agent in buying and selling, to give a
                  trusted person as a partner in business, to keep as a trust someone, to give on
                  rent, to have necessary employees for the care of the thing, to rent a place for
                  keeping goods, shops, etc., and to have all kinds of authorities so that it can
                  work in the best possible way and bring you maximum benefit.
                </p>

                <div className=" pt-3  flex gap-3">
                  <Input
                    type="checkbox"
                    checked={isCheckboxChecked}
                    onChange={(e) => setIsCheckboxChecked(e.target.checked)}
                  />
                  <p className=" text-sm font-medium capitalize">
                    I declare that the particulars written in this statement are based on complete
                    and accurate facts. The department "Welcome H abibi Trading Pakistan" is
                    authorized by me to conduct any legitimate business with the accumulated amount
                    from me.
                  </p>
                </div>
              </div>

              {Loading === true ? (
                <div className=" mx-auto flex justify-center items-center">
                  <button
                    disabled
                    type="button"
                    className=" bg-blue text-center mt-3   text-white py-2.5 border-b-black w-96  border-2 mx-auto my-4"
                  >
                    <svg
                      aria-hidden="true"
                      role="status"
                      className="inline w-4 h-4 me-3 text-white animate-spin"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="#E5E7EB"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentColor"
                      />
                    </svg>
                    Loading...
                  </button>
                </div>
              ) : (
                <Button
                  label={'Submit'}
                  type={'submit'}
                  className={
                    '     bg-blue text-center mt-3 justify-center  text-white py-2.5 border-b-black w-96  border-2 mx-auto my-4'
                  }
                />
              )}
            </form>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Add_ViewForm;
