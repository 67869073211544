import React, { useState } from 'react';
import Super_admin_web_portal from '../../../layout/Super_admin_web_portal';
import { HiOutlineArrowLeft } from 'react-icons/hi';

// Inline Loader Component
const Loader = () => (
  <div
    style={{
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 50
    }}
  >
    <div
      style={{
        border: '8px solid #f3f3f3', // Light gray
        borderTop: '8px solid #3498db', // Blue
        borderRadius: '50%',
        width: '50px',
        height: '50px',
        animation: 'spin 2s linear infinite'
      }}
    ></div>
  </div>
);

const ResetPassword = () => {
  const [email, setEmail] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('');
  const [loading, setLoading] = useState(false);

  // Handle form submission
  const handleResetPassword = async () => {
    setLoading(true);

    // Basic client-side validation for required fields
    if (!email || !newPassword) {
      setMessage('Both fields are required!');
      setMessageType('error');
      setLoading(false);
      return;
    }

    // You may add additional client-side validation for email and password
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setMessage('Please enter a valid email address.');
      setMessageType('error');
      setLoading(false);
      return;
    }

    // Password complexity check (example: at least 6 characters)
    if (newPassword.length < 6) {
      setMessage('Password must be at least 6 characters long.');
      setMessageType('error');
      setLoading(false);
      return;
    }

    // Prepare the request body
    const requestBody = {
      email: email.trim(),
      newPassword: newPassword.trim()
    };

    // Set headers
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify(requestBody)
    };

    try {
      // Fetch API call to reset password
      const response = await fetch(
        'https://welcome-habibi-backend.vercel.app/v1/admin/change-password',
        requestOptions
      );

      // Check if the response status is OK
      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      const result = await response.json();

      setLoading(false);

      // Check the response result and update the message accordingly
      if (result?.success === false) {
        setMessage(result?.message || 'Something went wrong.');
        setMessageType('error');
      } else {
        setMessage(result?.message || 'Password reset successful!');
        setMessageType('success');
      }
    } catch (error) {
      setLoading(false);
      setMessage(`An error occurred: ${error.message}`);
      setMessageType('error');
    }
  };

  return (
    <Super_admin_web_portal
      language={<p className="sm:block hidden">English | Urdu</p>}
      ToggleBtn={
        <div className="flex gap-4 items-center">
          <HiOutlineArrowLeft />
        </div>
      }
      title_props={
        <div className="w-96 relative">
          <h1 className="text-2xl font-bold">Reset Password</h1>
        </div>
      }
    >
      {/* Loader */}
      {loading && <Loader />}

      <div className="flex justify-center items-center w-full my-8">
        <img
          src={require('../../../assets/image/images_auth.jpeg')}
          alt="Please Reload..."
          className="max-w-full h-auto"
        />
      </div>

      {/* Reset Password Form */}
      <div className="w-full max-w-md mx-auto">
        {/* Email Input */}
        <div className="mb-4">
          <label htmlFor="email" className="block text-gray-700">
            Email
          </label>
          <input
            type="email"
            id="email"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="w-full p-3 border rounded-md"
            autoComplete="off" // Disable autocomplete
          />
        </div>

        {/* New Password Input */}
        <div className="mb-4">
          <label htmlFor="newPassword" className="block text-gray-700">
            New Password
          </label>
          <input
            type="password" // Changed to password type for security
            id="newPassword"
            placeholder="Enter your new password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            className="w-full p-3 border rounded-md"
            autoComplete="off" // Disable autocomplete
          />
        </div>

        {/* Reset Button */}
        <button
          onClick={handleResetPassword}
          className="w-full p-3 bg-primary text-white rounded-md"
        >
          Update Password
        </button>

        {/* Message Section */}
        {message && (
          <div
            style={{
              marginTop: '16px',
              textAlign: 'center',
              fontSize: '14px',
              color: messageType === 'error' ? 'red' : 'green'
            }}
          >
            {message}
          </div>
        )}
      </div>
    </Super_admin_web_portal>
  );
};

export default ResetPassword;
