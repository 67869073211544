import React, { useEffect, useState } from 'react';
import { useAllinvestorsQuery } from '../../../store/services/investorService';
import { Link, useParams } from 'react-router-dom';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';
import axios from 'axios';
import Investor_web_portal from '../../../layout/Investor_web_portal';
import { HiOutlineArrowLeft } from 'react-icons/hi';
const ViewPlanDetails = () => {
  const [state, setState] = useState('details');
  const { id } = useParams();
  const { data, isFetching } = useAllinvestorsQuery();
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [filteredDeposits, setFilteredDeposits] = useState([]);
  const currentPageData = filteredDeposits.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePageChange = (newPage) => setCurrentPage(newPage);
  useEffect(() => {
    filterDeposits();
  }, [data, searchTerm]);

  const [viewPlan, setViewPlan] = useState({});
  const [viewInvestor, setViewInvestor] = useState([]);
  useEffect(() => {
    axios
      .get(`https://welcome-habibi-backend.vercel.app/v1/admin/plan-detail/${id}`)
      .then((res) => {
        setViewPlan(res.data.data);
      })
      .catch((error) => {});

    axios
      .get(`https://welcome-habibi-backend.vercel.app/v1/admin/plan-investor/${id}`)
      .then((res) => {
        setViewInvestor(res.data.data);
      })
      .catch((error) => {});
  }, []);

  const filterDeposits = () => {
    let filtered = viewInvestor?.investors || [];

    if (searchTerm.trim()) {
      const lowercasedSearchTerm = searchTerm.toLowerCase();
      filtered = filtered.filter((val) => {
        const investmentIdMatch = val?.name?.toLowerCase().includes(lowercasedSearchTerm);
        const managerIdMatch = val?.cnic?.toLowerCase().includes(lowercasedSearchTerm);

        return investmentIdMatch || managerIdMatch;
      });
    }

    setFilteredDeposits(filtered);
    setCurrentPage(1);
  };

  return (
    <Investor_web_portal
      language={
        <>
          <p className=" sm:block  hidden">English | Urdu</p>
        </>
      }
      ToggleBtn={
        <div className=" flex gap-4 items-center">
          <HiOutlineArrowLeft />
          <h2 className="">Rewards</h2>
        </div>
      }
      title_props={
        <>
          <h1 className="  font-semibold text-xl  text-primary pb-4">Welcome Habibi Trading</h1>
        </>
      }
    >
      <div>
        <div>
          <ul className=" p-0 flex   gap-16 items-center">
            <li onClick={() => setState('details')} className=" text-black  text-lg font-semibold ">
              Plan Detail
            </li>
          </ul>
        </div>

        {state === 'details' ? (
          <>
            <div className=" w-full mt-12">
              <img src={viewPlan?.planImage} className=" mx-auto" alt="" />
            </div>

            <div className=" text-center p-8">
              <h1 className=" text-primary text-xl font-semibold">{viewPlan?.planName}</h1>
              <img src={viewPlan?.planDescription} className=" mx-auto  w-full" alt="" />
            </div>
          </>
        ) : (
          <section className="mb-20 mt-12 text-gray-800">
            <div className="block rounded-lg ">
              <div className="flex flex-col">
                <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="inline-block min-w-full sm:px-6 lg:px-8">
                    <div className="overflow-hidden">
                      <table className="min-w-full mb-0">
                        <thead className=" ">
                          <tr className=" rounded-lg whitespace-nowrap ">
                            <th scope="col" className=" text-sm  text-darkGray  font-medium px-6">
                              Name
                            </th>
                            <th scope="col" className=" text-sm text-darkGray    font-medium px-6 ">
                              CNIC No
                            </th>

                            <th scope="col" className="text-sm  text-darkGray   font-medium px-6 ">
                              Total Deposits
                            </th>

                            <th
                              scope="col"
                              className="text-sm  text-darkGray     font-medium px-6 "
                            >
                              Total Profit
                            </th>
                            <th
                              scope="col"
                              className="text-sm    text-darkGray   font-medium px-6 "
                            >
                              Total Deducations
                            </th>
                          </tr>
                        </thead>
                        <tbody className="">
                          {currentPageData?.map((item, index) => {
                            return (
                              <>
                                <tr className="bg-white border-t-8 border-b-8    border-[#F6F6F6]  rounded-md ">
                                  <th
                                    scope="row"
                                    className="text-sm font-normal px-6 py-4   whitespace-nowrap "
                                  >
                                    <Link
                                      onClick={() => localStorage.setItem('iName', item?.name)}
                                      to={`/super_admin/investorsDetails/${item?.cnic}`}
                                      className="mb-0.5 font-medium  text-secondary"
                                    >
                                      {item?.name}
                                    </Link>
                                  </th>
                                  <td className="align-middle text-sm font-normal px-6 py-4 whitespace-nowrap  text-center">
                                    <span className=" text-base text-black  py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline   bg-green-200  rounded-full">
                                      {item?.cnic}
                                    </span>
                                  </td>

                                  <td className="align-middle text-center text-sm font-normal px-6 py-4 whitespace-nowrap text-left">
                                    <span className=" text-base text-black  py-1 px-2.5 leading-none  whitespace-nowrap    bg-green-200  rounded-full">
                                      {item?.totalInvestment}
                                    </span>
                                  </td>
                                  <td className="align-middle text-center text-sm font-normal px-6 py-4 whitespace-nowrap text-left">
                                    <span className=" text-base text-black  py-1 px-2.5 leading-none  whitespace-nowrap    bg-green-200  rounded-full">
                                      {item?.totalProfit}
                                    </span>
                                  </td>

                                  <td className="align-middle  text-center text-sm font-normal px-6 py-4 whitespace-nowrap">
                                    <span className=" text-base text-black  py-1 px-2.5 leading-none  whitespace-nowrap    bg-green-200  rounded-full">
                                      {item?.totalDeduction}
                                    </span>
                                  </td>
                                </tr>
                              </>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div className=" flex justify-end items-center  py-4 gap-6">
                <button
                  className={`${
                    currentPage === 1 ? 'bg-gray-500  text-white' : 'bg-secondary text-white'
                  } flex justify-center items-center  w-10 h-10 rounded-md`}
                  disabled={currentPage === 1}
                  onClick={() => handlePageChange(currentPage - 1)}
                >
                  <FaAngleLeft size={20} />
                </button>
                <span className="  text-primary  font-semibold">
                  {currentPage} of {Math.ceil(filteredDeposits.length / itemsPerPage)}
                </span>
                <button
                  className={`${
                    currentPage * itemsPerPage >= filteredDeposits.length
                      ? 'bg-gray-500  text-white'
                      : 'bg-secondary text-white'
                  } flex justify-center items-center  w-10 h-10 rounded-md`}
                  disabled={currentPage * itemsPerPage >= filteredDeposits.length}
                  onClick={() => handlePageChange(currentPage + 1)}
                >
                  <FaAngleRight size={20} />
                </button>
              </div>
            </div>
          </section>
        )}
      </div>
    </Investor_web_portal>
  );
};

export default ViewPlanDetails;
