import React, { useEffect, useState } from 'react';
import Button from '../../../components/Button';
import Input from '../../../components/Input';
import { IoSearchOutline } from 'react-icons/io5';
import { HiOutlineArrowLeft } from 'react-icons/hi';
import { Link } from 'react-router-dom';
import { useAllinvestorsQuery } from '../../../store/services/investorService';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';
import Manager_web_portal from '../../../layout/Manager_web_portal';
import * as XLSX from 'xlsx'; // Importing xlsx library
const Investors = () => {
  const { data, isFetching } = useAllinvestorsQuery();
  console.log(data, isFetching, '====================================');
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 6;
  const [filteredDeposits, setFilteredDeposits] = useState([]);
  const currentPageData = filteredDeposits.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePageChange = (newPage) => setCurrentPage(newPage);

  useEffect(() => {
    filterDeposits();
  }, [data, searchTerm]);

  const filterDeposits = () => {
    let filtered = data?.investors || [];

    if (searchTerm.trim()) {
      const lowercasedSearchTerm = searchTerm.toLowerCase();
      filtered = filtered.filter((val) => {
        const investmentIdMatch = val?.fullName?.toLowerCase().includes(lowercasedSearchTerm);
        const managerIdMatch = val?.cnic?.toLowerCase().includes(lowercasedSearchTerm);

        return investmentIdMatch || managerIdMatch;
      });
    }

    setFilteredDeposits(filtered);
    setCurrentPage(1);
  };

  const ExportCVC = async (item) => {
    try {
      if (!item?.cnic) {
        console.error('Invalid CNIC provided.');
        return;
      }

      console.log('Exporting data for CNIC:', item?.cnic);

      const response = await fetch(
        `https://welcome-habibi-backend.vercel.app/v1/manager/investor-all-details/${item?.cnic}`,
        { method: 'GET', redirect: 'follow' }
      );

      if (!response.ok) {
        console.error('Failed to fetch data:', response.statusText);
        return;
      }

      const result = await response.json();
      // console.log('API Response:', result);

      const investorDetails = result?.investorDetails;
      const enrolledPlans = investorDetails?.enrolledPlans;

      if (!investorDetails || !Array.isArray(enrolledPlans)) {
        console.error('Invalid data structure.');
        return;
      }

      // Flatten investor details
      const investorData = {
        'Investor Name': investorDetails?.investorName?.fullName,
        CNIC: investorDetails?.investorName?.cnic,
        'Contact Info': investorDetails?.investorName?.contactInfo,
        Password: investorDetails?.investorName?.password,
        'Bio Status': investorDetails?.investorName?.bioStatus,
        'Created At': investorDetails?.investorName?.createdAt,
        Images: investorDetails?.investorName?.images
      };

      // Prepare a combined array for the sheet (Investments + Investor Data)
      const dataForExport = [];

      // Include investor info as the first row
      dataForExport.push(investorData);

      // Loop through each enrolled plan and its investments
      enrolledPlans.forEach((plan) => {
        plan.investments.forEach((investment) => {
          const investmentData = {
            'Plan Category': plan.planCategory,
            'Plan Name': plan.planName,
            'Deposit Date': investment.depositDate,
            'Deposit Type': investment.depositType,
            'Receipt Number': investment.receiptNumber,
            Amount: investment.amount,
            'Bank Name': investment.bankName,
            'Branch Name': investment.branchName,
            Reference: investment.reference,
            Remark: investment.remark,
            'Acknowledge Auth': investment.acknowledgeAuth,
            'Acknowledge Date': investment.acknowledgeDate,
            'Acknowledge Status': investment.acknowledgeStatus,
            Deduction: investment.deduction,
            Profit: investment.profit,
            Slip: investment.slip
          };
          dataForExport.push(investmentData);
        });
      });

      // Convert the data into a worksheet
      const ws = XLSX.utils.json_to_sheet(dataForExport);

      // Create a new workbook and append the worksheet
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Investor Data');

      // Write the file
      XLSX.writeFile(wb, `Investor_${investorDetails?.investorName?.fullName}.xlsx`);

      console.log('Export successful!');
    } catch (error) {
      console.error('Error exporting data:', error);
    }
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <Manager_web_portal
      language={
        <>
          <p className=" sm:block  hidden">English | Urdu</p>
        </>
      }
      ToggleBtn={
        <div className=" flex gap-4 items-center">
          <HiOutlineArrowLeft />
          <h2 className="">Investors</h2>
        </div>
      }
      title_props={
        <>
          <div className=" w-96 relative">
            <Input
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder={''}
              className={' border bg-white w-full rounded-full'}
            />

            <Button
              Icon={<IoSearchOutline className=" text-white text-xl" />}
              label={'search'}
              className={
                ' absolute top-0 right-0 bg-secondary  text-white uppercase font-normal rounded-full'
              }
            />
          </div>
        </>
      }
    >
      <div className="  w-full  mb-4  sm:hidden block relative">
        <Input
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder={''}
          className={' border bg-white w-full rounded-full'}
        />

        <Button
          Icon={<IoSearchOutline className=" text-white text-xl" />}
          label={'search'}
          className={
            ' absolute top-0 right-0 bg-secondary text-white uppercase font-normal rounded-full'
          }
        />
      </div>
      <div className=" sm:flex block    justify-between items-center">
        <div>
          <h2 className="  sm:block hidden">Investors</h2>
        </div>

        <div className=" flex gap-3 items-center"></div>
      </div>

      <section className="mb-20 mt-7 text-gray-800">
        <div className="block rounded-lg ">
          <div className="flex flex-col overflow-x-auto">
            <div className=" sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full sm:px-6 lg:px-8">
                <div className="overflow-hidden">
                  <table className="min-w-full mb-0">
                    <thead className=" ">
                      <tr className=" rounded-lg whitespace-nowrap ">
                        <th scope="col" className=" text-sm  text-darkGray  font-medium px-6">
                          Name
                        </th>
                        <th scope="col" className=" text-sm text-darkGray    font-medium px-6 ">
                          Phone Number
                        </th>
                        <th scope="col" className=" text-sm text-darkGray    font-medium px-6 ">
                          CNIC No
                        </th>

                        <th scope="col" className="text-sm  text-darkGray   font-medium px-6 ">
                          CNIC Copy
                        </th>

                        <th scope="col" className="text-sm  text-darkGray     font-medium px-6 ">
                          Password
                        </th>
                        {/* <th
                          scope="col"
                          className="text-sm    text-darkGray   font-medium px-6 "
                        >
                          Status
                        </th> */}
                      </tr>
                    </thead>
                    <tbody className="">
                      {currentPageData?.map((item, index) => {
                        return (
                          <>
                            <tr className="bg-white border-t-8 border-b-8    border-[#F6F6F6]  rounded-md ">
                              <th
                                scope="row"
                                className="text-sm font-normal px-6 py-4   whitespace-nowrap "
                              >
                                <Link
                                  onClick={() => localStorage.setItem('iName', item?.fullName)}
                                  to={`/manager/investorsDetails/${item?.cnic}/${item?._id}`}
                                  className="mb-0.5 font-medium  text-secondary"
                                >
                                  {item?.fullName}
                                </Link>
                              </th>
                              <td className="align-middle text-sm font-normal px-6 py-4 whitespace-nowrap  text-center">
                                <span className=" text-base text-black  py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline   bg-green-200  rounded-full">
                                  {item?.contactInfo}
                                </span>
                              </td>
                              <td className="align-middle text-sm font-normal px-6 py-4 whitespace-nowrap  text-center">
                                <span className=" text-base text-black  py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline   bg-green-200  rounded-full">
                                  {item?.cnic}
                                </span>
                              </td>

                              <td className="align-middle text-center text-sm font-normal px-6 py-4 whitespace-nowrap text-left">
                                <span className=" text-base text-secondary  py-1 px-2.5 leading-none  whitespace-nowrap    bg-green-200  rounded-full">
                                  {item?.images?.slice(-6)}
                                </span>
                              </td>
                              <td className="align-middle text-center text-sm font-normal px-6 py-4 whitespace-nowrap text-left">
                                <span className=" text-base text-black  py-1 px-2.5 leading-none  whitespace-nowrap    bg-green-200  rounded-full">
                                  {item?.password}
                                </span>
                              </td>
                              <td className="align-middle text-center text-sm font-normal px-6 py-4 whitespace-nowrap">
                                <button
                                  className="bg-secondary text-white px-4 py-2 rounded-md"
                                  onClick={() => ExportCVC(item)}
                                >
                                  Export
                                </button>
                              </td>

                              {/* <td className="align-middle  text-sm font-normal px-6 py-4 whitespace-nowrap">
                                <span className=" text-base text-black  py-1 px-2.5 leading-none  whitespace-nowrap    bg-green-200  rounded-full">
                                 pending
                                </span>
                              </td> */}
                            </tr>
                          </>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className=" flex justify-end items-center  py-4 gap-6">
            <button
              className={`${
                currentPage === 1 ? 'bg-gray-500  text-white' : 'bg-secondary text-white'
              } flex justify-center items-center  w-10 h-10 rounded-md`}
              disabled={currentPage === 1}
              onClick={() => handlePageChange(currentPage - 1)}
            >
              <FaAngleLeft size={20} />
            </button>
            <span className="  text-primary  font-semibold">
              {currentPage} of {Math.ceil(filteredDeposits.length / itemsPerPage)}
            </span>
            <button
              className={`${
                currentPage * itemsPerPage >= filteredDeposits.length
                  ? 'bg-gray-500  text-white'
                  : 'bg-secondary text-white'
              } flex justify-center items-center  w-10 h-10 rounded-md`}
              disabled={currentPage * itemsPerPage >= filteredDeposits.length}
              onClick={() => handlePageChange(currentPage + 1)}
            >
              <FaAngleRight size={20} />
            </button>
          </div>
        </div>
      </section>
    </Manager_web_portal>
  );
};

export default Investors;
