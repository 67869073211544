import React, { useEffect, useState } from 'react';
import Button from '../../../components/Button';
import { HiOutlineArrowLeft } from 'react-icons/hi';
import { IoSearchOutline } from 'react-icons/io5';
import Input from '../../../components/Input';
import ViewEvents from '../../ViewEvents/ViewEvents';
import { Link } from 'react-router-dom';
import Investor_web_portal from '../../../layout/Investor_web_portal';
import axios from 'axios';

const InvestorActivePlans = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [getMyPlans, setGetMyPlans] = useState([]);

  const cnicId = JSON.parse(localStorage.getItem('investor_data'));

  useEffect(() => {
    axios
      .get(`https://welcome-habibi-backend.vercel.app/v1/worker/my-enrolled-plan/${cnicId?.cnic}`)
      .then((res) => {
        setGetMyPlans(res.data.enrolledPlans);
      })
      .catch((error) => {});
  }, []);

  return (
    <Investor_web_portal
      language={
        <>
          <p className=" sm:block  hidden">English | Urdu</p>
        </>
      }
      ToggleBtn={
        <div className=" flex gap-4 items-center">
          <HiOutlineArrowLeft />
          <h2 className=""> Active Plans</h2>
        </div>
      }
      title_props={
        <>
          <div className=" w-96 relative">
            <Input placeholder={''} className={' border bg-white w-full rounded-full'} />

            <Button
              Icon={<IoSearchOutline className=" text-white text-xl" />}
              label={'search'}
              className={
                ' absolute top-0 right-0 bg-secondary  text-white uppercase font-normal rounded-full'
              }
            />
          </div>
        </>
      }
    >
      <ViewEvents
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        // getData={view}
      />

      <div className=" container m-auto pt-6">
        <div className=" flex justify-between gap-3 items-center">
          <h1 className=" font-semibold text-xl">Active Plans</h1>
        </div>

        <div className="  w-full  mb-4  sm:hidden block relative">
          <Input placeholder={''} className={' border bg-white w-full rounded-full'} />

          <Button
            Icon={<IoSearchOutline className=" text-white text-xl" />}
            label={'search'}
            className={
              ' absolute top-0 right-0 bg-secondary text-white uppercase font-normal rounded-full'
            }
          />
        </div>

        <div className=" my-12   grid  md:grid-cols-3 grid-cols-1 gap-12">
          {getMyPlans?.map((item, index) => {
            return (
              <div className=" ">
                <div className=" relative h-80">
                  <img src={item?.planImage} className="  w-full h-full  object-cover" alt="" />
                  <div className=" absolute  top-0  bg-[rgba(0,0,0,0.1)]   h-full rounded-md w-full"></div>
                </div>

                <div className="  bg-white px-3  py-10 flex flex-col  justify-center">
                  <p className=" text-primary font-semibold text-xl text-center ">
                    {item?.planName}
                  </p>

                  <p className=" text-primary  py-5  text-center">{item?.planCategory}</p>
                  <Link to={`/investor/view-plan/${item?.planId}`}>
                    <Button
                      label={'Details'}
                      className={
                        ' bg-secondary     rounded-full justify-center w-44 mx-auto text-white  py-3.5'
                      }
                    />
                  </Link>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </Investor_web_portal>
  );
};

export default InvestorActivePlans;
