import { Link } from 'react-router-dom';
import Footer from '../../components/footer';
import ContactUs from '../../components/contactUs';
import Wrapper from '../../layout/Wrapper';
import Input from '../../components/Input';
import Button from '../../components/Button';
import { useLocation } from 'react-router-dom';
import { IoSearchOutline } from 'react-icons/io5';
import { useAllbranchsQuery } from '../../store/services/branchService';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { RiMapPinFill } from 'react-icons/ri';
import { useAlleventsQuery } from '../../store/services/eventService';
import { useEffect, useState } from 'react';
import { GoogleMap, LoadScript, MarkerF } from '@react-google-maps/api';
import { MdLocationPin } from 'react-icons/md';
import ViewEvents from '../ViewEvents/ViewEvents';
import { useAllalertsQuery } from '../../store/services/alertService';
import { useParams } from 'react-router-dom';

const Home = () => {
  const { data, isFetching } = useAlleventsQuery();
  const location = useLocation();

  const { data: alert, isFetching: Fetching, refetch } = useAllalertsQuery();

  const [currentLocation, setCurrentLocation] = useState({
    lat: 32.08574,
    lng: 72.659829
  });

  const libraries = ['places'];

  const [map, setMap] = useState(null);
  const [autocomplete, setAutocomplete] = useState(null);
  const [address, setAddress] = useState('');
  const [lat, setLat] = useState(currentLocation.lat);
  const [lng, setLng] = useState(currentLocation.lng);

  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const openModal2 = () => setIsModalOpen2(true);
  const closeModal2 = () => setIsModalOpen2(false);

  const [view, setView] = useState({});
  const [loading, setLoading] = useState(true); // Loader state
  const [apiError, setApiError] = useState(null); // To store error from API

  const id = location.pathname.split('/').pop();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true); // Start loader
      setApiError(null); // Reset errors
      try {
        const response = await fetch(
          `https://welcome-habibi-backend.vercel.app/v1/worker/single-investor/${id}`
        );
        const data = await response.json();
        if (data.success) {
          setView(data);
        } else {
          setApiError('Data Not Found');
        }
      } catch (error) {
        setApiError('An error occurred while fetching data: Please Contact Technical Team');
      }
      setLoading(false); // Stop loader
    };

    if (id) {
      fetchData();
    }
  }, [id]);

  return (
    <>
      <Wrapper
        ToggleBtn={
          <div className="md:hidden block">
            <img src={require('../../assets/image/menu.png')} alt="" />
          </div>
        }
        title_props={<h1 className="main_title">Welcome Habibi Trading</h1>}
        className="flex justify-center items-center"
      >
        {location.pathname === '/' ? (
          <form className="bg-white shadow-2xl md:flex block w-full rounded">
            <div className="text-center md:w-[50%] w-full">
              <img
                src={require('../../assets/image/login_bg.png')}
                className="h-full object-cover"
                alt=""
              />
            </div>
            <ViewEvents
              isModalOpen={isModalOpen2}
              setIsModalOpen={setIsModalOpen2}
              getData={view}
            />
            <div className="bg-[#0054A5] bg-BG bg-center bg-cover md:px-14 px-5 py-3 flex items-center justify-center md:w-[50%] w-full">
              <div>
                <h1 className="font-semibold text-4xl text-white pb-4">Welcome Habibi Trading</h1>
                <p className="text-white font-light text-lg">
                  We are building your future so feel safe & secure. A Riba Free business with
                  sharia guidance.
                </p>
                <div className="mt-8 bg-white">
                  <button
                    type="submit"
                    value={'WHT ALERTS'}
                    className="bg-blue text-xl text-center w-full py-3 text-white font-semibold cursor-pointer"
                  >
                    WHT Alerts
                  </button>
                  <div>
                    <ul className="h-52 overflow-y-scroll">
                      {alert?.data?.map((item, index) => {
                        return (
                          <>
                            <li className="p-4 text-center text-sm">{item?.message}</li>
                            <hr />
                          </>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </form>
        ) : (
          // Show the form with API data as a table for "checkinvestment/{id}"
          <form className="bg-white shadow-2xl md:flex block w-full rounded">
            <div className="text-center md:w-[50%] w-full">
              <img
                src={require('../../assets/image/login_bg.png')}
                className="h-full object-cover"
                alt=""
              />
            </div>
            {loading ? (
              <div className="flex justify-center items-center h-full">
                <div className="spinner"></div> {/* Add your spinner or loader here */}
              </div>
            ) : apiError ? (
              <div className="w-full text-center py-20 px-10 bg-primary rounded-md shadow-lg">
                <p className="text-xl font-semibold text-[red]">{apiError}</p>
                <p className="mt-4 text-lg text-white">
                  Please beware of scammers and fraudsters. Always ensure you are interacting with
                  the official team of Welcome Habibi Trading.
                  <br />
                  For any concerns, please contact us directly at:
                  <br />
                  <strong>Phone:</strong> 03137013113, 03025794968
                  <br />
                  <strong>Email:</strong> amirshahzad629220@gmail.com
                </p>
              </div>
            ) : (
              <div className="bg-[#0054A5] bg-BG bg-center bg-cover md:px-14 px-5 py-3 flex items-center justify-center md:w-[50%] w-full">
                <div className="w-full ">
                  <h1 className="font-semibold text-4xl text-white pb-5">Investment Details</h1>

                  <table className="w-full table-auto text-white">
                    <thead>
                      {/* <tr>
                        <th className="px-4 py-2 text-left">Field</th>
                        <th className="px-4 py-2 text-left">Value</th>
                      </tr> */}
                    </thead>
                    <tbody>
                      <tr>
                        <td className=" py-2">Investment ID</td>
                        <td className=" py-2 text-[11px] sm:text-base md:text-lg lg:text-[14px] break-words">
                          {view?.investment?._id}
                        </td>
                      </tr>

                      {/* <tr>
                        <td className="px-4 py-2">Worker Name</td>
                        <td className="px-4 py-2">{view?.investment?.createdBy?.name}</td>
                      </tr> */}
                      <tr>
                        <td className=" py-2">Investor Name</td>
                        <td className=" py-2">{view?.investment?.name}</td>
                      </tr>
                      <tr>
                        <td className=" py-2">Investor CNIC</td>
                        <td className=" py-2">{view?.investment?.cnic}</td>
                      </tr>
                      <tr>
                        <td className=" py-2">Deposit Date</td>
                        <td className=" py-2">{view?.investment?.depositDate}</td>
                      </tr>
                      <tr>
                        <td className=" py-2">Amount</td>
                        <td className=" py-2">{view?.investment?.amount}</td>
                      </tr>
                      <tr>
                        <td className=" py-2">Receipt No</td>
                        <td className=" py-2">{view?.investment?.receiptNumber}</td>
                      </tr>
                      <tr>
                        <td className=" py-2">Plan Name</td>
                        <td className=" py-2">{view?.investment?.planId?.planName}</td>
                      </tr>
                      <tr>
                        <td className=" py-2">Reference</td>
                        <td className=" py-2">{view?.investment?.reference}</td>
                      </tr>
                    </tbody>
                  </table>
                  <div className="text-[12px] text-white italic pb-2">
                    <p>
                      "Dear member! The login information for your portal has been emailed to you."
                    </p>
                  </div>
                </div>
              </div>
            )}
          </form>
        )}
      </Wrapper>

      <div id="gallerySection" className="container m-auto md:px-16 px-6 md:py-20 py-12">
        <div className="md:w-[40%] w-full mx-auto text-center">
          <h1 className="font-semibold text-4xl">WHT GALLERY</h1>
          <p className="py-6">A journey to the Bright future.</p>
        </div>

        <div className="my-12 grid md:grid-cols-2 grid-cols-1 gap-12">
          {data?.events?.map((item, index) => {
            return (
              <div key={index}>
                <p className="text-primary font-semibold text-xl text-center py-3">{item?.title}</p>
                <div className="relative h-96">
                  <img src={item?.images[0]} className="w-full h-full object-cover" alt="" />
                  <div className="absolute top-0 bg-[rgba(0,0,0,0.1)] h-full rounded-md w-full">
                    <div className="h-full pb-4 flex justify-end items-end">
                      <Button
                        onClick={() => {
                          openModal2();
                          setView(item);
                        }}
                        label={'Show More'}
                        className={'bg-secondary justify-center w-36 mx-auto text-white py-3.5'}
                      />
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <ContactUs />
      <Footer />
    </>
  );
};

export default Home;
