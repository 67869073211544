import { FiMenu } from 'react-icons/fi';
import axios from 'axios';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { IoNotificationsOff, IoCloseCircleOutline } from 'react-icons/io5';
import { MdClose } from 'react-icons/md';
const SuperAdminNav = ({ openSidebar, side, closeSidebar, title_props, language, ToggleBtn }) => {
  const [myNotification, setMyNotification] = useState([]);
  const [isModalOpen, setModalOpen] = useState(false);

  const fetchNotifications = () => {
    const storedData = localStorage.getItem('adminToken');

    const allLocalStorageItems = { ...localStorage };
    // console.log('aldsjf', allLocalStorageItems);
    axios
      .get(`https://welcome-habibi-backend.vercel.app/v1/manager/all-notification/${storedData}`)
      .then((res) => {
        // console.log(res.data.data, 'Fetched Notifications');
        setMyNotification(res.data.data.reverse());
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    fetchNotifications();
  }, []);

  const handleNotificationClick = () => {
    setModalOpen(!isModalOpen);
  };

  const handleDeleteNotification = (id) => {
    const storedData = localStorage.getItem('adminToken');
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');

    const raw = JSON.stringify({
      userId: storedData
    });

    const requestOptions = {
      method: 'DELETE',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(
      `https://welcome-habibi-backend.vercel.app/v1/admin/delete-notification/${id}`,
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => {
        let data = JSON.parse(result);
        console.log(data, 'data');
        if (data.success === true) {
          // Refresh the Notification List
          fetchNotifications();
        }
      })
      .catch((error) => console.error(error));
  };

  const handleReadNotification = (id) => {
    const storedData = localStorage.getItem('adminToken');

    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');

    const raw = JSON.stringify({
      userId: storedData
    });

    const requestOptions = {
      method: 'PUT',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(
      `https://welcome-habibi-backend.vercel.app/v1/admin/read-notification/${id}`,
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => {
        let data = JSON.parse(result);
        console.log(data, 'data');
        if (data.success === true) {
          // Refresh the Notification List
          fetchNotifications();
        }
      })
      .catch((error) => console.error(error));
  };
  return (
    <nav
      className={`fixed  z-20 bg-white top-0 right-0   ${
        side === 'left-0 md:-left-64' ? 'left-0' : 'left-0 md:left-64'
      }`}
    >
      <div className="   w-full flex justify-between  items-center p-6">
        <div className=" flex items-center gap-4">
          <div onClick={openSidebar} className="  md:hidden  block">
            {ToggleBtn}
          </div>
          <div className="  hidden md:block">{title_props}</div>
        </div>

        <div className=" flex items-center gap-6">
          <div>
            <p>{language}</p>
          </div>
          <div className=" relative">
            <img
              src={require('../../../assets/image/Notification.png')}
              onClick={handleNotificationClick}
              style={{ cursor: 'pointer' }}
            />
            {myNotification?.length > 0 ? (
              <span className="   bg-green w-3 h-3 right-0 rounded-full absolute top-0"></span>
            ) : (
              <span className=" bg-[#FF6A6A] w-3 h-3 right-0 rounded-full absolute top-0"></span>
            )}

            {/* Notification Modal */}
            {isModalOpen && (
              <div className="absolute right-0 top-12 z-30">
                <div className="bg-white p-4 rounded shadow-lg w-96">
                  {' '}
                  {/* Increased width */}
                  {/* Header */}
                  <div className="flex justify-between items-center mb-2">
                    <h2 className="text-xl font-semibold">Notifications</h2>
                    <MdClose
                      className="cursor-pointer text-gray-500 hover:text-gray-700"
                      onClick={() => setModalOpen(false)}
                      size={25}
                    />
                  </div>
                  {/* Notifications List */}
                  <ul className="h-72 overflow-y-auto space-y-4">
                    {myNotification?.length > 0 ? (
                      myNotification?.map((item, index) => (
                        <li
                          key={index}
                          className={`flex gap-4 w-full items-center border-b border-gray-200 pb-2 
                ${item?.status === 'unread' ? 'bg-[#ADD8E6]' : 'bg-white'} p-3 rounded-lg transition-all`}
                        >
                          {/* Logo */}
                          <div className="w-12 h-12 flex justify-center items-center">
                            <img
                              src={require('../../../assets/image/logo.png')}
                              className="w-full h-full object-contain"
                              alt="Notification Logo"
                            />
                          </div>

                          {/* Notification Content */}
                          <div className="flex-1">
                            <p className="text-sm font-medium">{item?.message}</p>
                            <span className="text-xs text-gray-500 block">
                              {moment(item?.createdAt).format('DD-MM-YYYY')}
                            </span>
                            {item?.status === 'unread' && (
                              <button
                                className="text-blue-500 text-xs mt-1 underline"
                                onClick={() => handleReadNotification(item?._id)}
                              >
                                Mark as Read
                              </button>
                            )}
                          </div>

                          {/* Delete Button */}
                          <button
                            className="text-red-500 hover:text-red-700"
                            onClick={() => handleDeleteNotification(item?._id)} // Call function with notification ID
                          >
                            <IoCloseCircleOutline size={20} />
                          </button>
                        </li>
                      ))
                    ) : (
                      <div className="text-center w-full pt-4">
                        <IoNotificationsOff size={60} className="text-gray-400 mx-auto" />
                        <span className="font-semibold text-gray-600">No message yet</span>
                      </div>
                    )}
                  </ul>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
};
export default SuperAdminNav;
