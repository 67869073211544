import React, { useState, useEffect, useCallback, useRef } from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';
import { MdClose } from 'react-icons/md';
import Modal from '../../../components/modal';
import Input from '../../../components/Input';
import Button from '../../../components/Button';
import { Base_url } from '../../../utils/Base_url';
import Datepicker from 'react-tailwindcss-datepicker';
import { MdOutlineFileUpload } from 'react-icons/md';
import { useUploadImageMutation } from '../../../store/services/uploadService';
import { useForm } from '../../../hooks/Form';
import { useCreateMutation } from '../../../store/services/depositService';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setSuccess } from '../../../store/reducers/globalReducer';

const Edit_collection = ({ isModalOpen, setIsModalOpen, closeModal, getData, setUsers }) => {
  const [uploadImage, { isLoading, data, error }] = useUploadImageMutation();

  console.log(getData);
  const [value, setValue] = useState('');
  const [loading, setLaoder] = useState(false);
  console.log(data?.[0]);
  const { workerToken } = useSelector((state) => state.authReducer);

  const [state, setState] = useState({
    name: '',
    cnic: '',
    amount: '',
    remark: '',
    reference: '',
    bankName: '',
    branchName: '',
    depositType: '',
    receiptNumber: ''
  });

  console.log(state.depositType);

  const handleInputs = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const [isEnable, setIsEnable] = useState(true);
  const [selectedImage, setSelectedImage] = useState(null);

  const [selectedImages, setSelectedImages] = useState(null);

  // alert(selectedImages)

  const [name, setName] = useState('');

  const [checked, setChecked] = useState(false);
  const fileInputRef = useRef(null);

  const handleDragOver = useCallback((e) => {
    e.preventDefault();
  }, []);

  const handleDrop = useCallback((e) => {
    e.preventDefault();
    const file = processFile(e.dataTransfer.files[0]);
    // uploadImage(file);
    setSelectedImages(file);
  }, []);

  const handleFileChange = (e) => {
    processFile(e.target.files[0]);
    // uploadImage(e.target.files[0]);
    setSelectedImages(e.target.files[0]);
  };

  const processFile = (file) => {
    // uploadImage(file);
    setSelectedImages(file);
    if (file && file.type.startsWith('image/')) {
      const reader = new FileReader();
      reader.onload = () => {
        setSelectedImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const triggerFileInput = () => {
    fileInputRef.current.click();
  };

  // alert(value.startDate)

  const [create, response] = useCreateMutation();
  const [formData, setForms] = useState([]);

  const onSubmit = async (values) => {
    const isPatternCorrect = /^\d{13}$/.test(state.cnic.replace(/[-\s]/g, ''));

    setLaoder(true);
    let profilephoto = getData?.slip || ' ';

    try {
      let param = new FormData();
      param.append('images', selectedImages);

      const uploadResponse = await axios.post(
        `https://file-upload-ashen.vercel.app/api/upload`,
        param
      );

      profilephoto = uploadResponse.data.data[0];
      setForms(profilephoto);
    } catch (error) {
      console.log(error);
    }

    // Construct the updated params
    const params = {
      workerId: workerToken,
      name: values.name.value,
      amount: Number(values.amount.value),
      remark: values.remark.value,
      reference: values.reference.value,
      bankName: values.bankName.value,
      branchName: values.branchName.value,
      depositType: values.depositType.value,
      receiptNumber: values.receiptNumber.value,
      slip: !selectedImage ? getData?.slip : profilephoto
    };

    axios
      .post(
        `https://welcome-habibi-backend.vercel.app/v1/manager/update-investments/${getData?._id}`,
        params
      )
      .then((res) => {
        if (res.data.success === true) {
          setLaoder(false);
          toast.success('Investor updated successfully!');

          // **Identify changes**
          let changes = [];
          if (values.name.value !== getData.name)
            changes.push(`name: ${getData.name} : ${values.name.value}`);
          if (Number(values.amount.value) !== getData.amount)
            changes.push(`amount: ${getData.amount} : ${values.amount.value}`);
          if (values.remark.value !== getData.remark)
            changes.push(`remark: ${getData.remark} : ${values.remark.value}`);
          if (values.reference.value !== getData.reference)
            changes.push(`reference: ${getData.reference} : ${values.reference.value}`);
          if (values.bankName.value !== getData.bankName)
            changes.push(`bankName: ${getData.bankName} : ${values.bankName.value}`);
          if (values.branchName.value !== getData.branchName)
            changes.push(`branchName: ${getData.branchName} : ${values.branchName.value}`);
          if (values.depositType.value !== getData.depositType)
            changes.push(`depositType: ${getData.depositType} : ${values.depositType.value}`);
          if (values.receiptNumber.value !== getData.receiptNumber)
            changes.push(`receiptNumber: ${getData.receiptNumber} : ${values.receiptNumber.value}`);
          if (profilephoto !== getData?.slip) changes.push(`slip updated`);

          const changesText = changes.length > 0 ? changes.join(', ') : 'No major changes.';

          // **Send notification**
          const notificationMessage = `Admin edited investor '${values.name.value}' details. Changes: ${changesText}`;

          const raw = JSON.stringify({
            userType: ['manager', 'admin'],
            message: notificationMessage
          });

          const requestOptions = {
            method: 'POST',
            body: raw,
            headers: { 'Content-Type': 'application/json' },
            redirect: 'follow'
          };

          fetch(
            'https://welcome-habibi-backend.vercel.app/v1/manager/general-notification',
            requestOptions
          )
            .then((response) => response.text())
            .then((result) => {
              let notificationData = JSON.parse(result);
              window.location.reload();
            })
            .catch((error) => console.error(error));
        } else {
          toast.error('Something Went Wrong.');
        }
      })
      .catch((error) => {
        console.log(error);
        toast.error('Server Error.');
      });
  };

  const [isUpdateOpen2, setIsUpdateOpen2] = useState(false);

  const UpdateModal2 = () => {
    setIsUpdateOpen2(true);
  };

  const closeUpdateModal2 = () => {
    setIsUpdateOpen2(false);
  };

  console.log(isUpdateOpen2);

  return (
    <div>
      <Modal isOpen={isModalOpen} onClose={closeModal} className={' md:w-[80%] w-[100%]'}>
        <div className="">
          <div className=" p-3 flex justify-between items-center">
            <div></div>

            <MdClose className="  text-gray-500" onClick={() => setIsModalOpen(false)} size={25} />
          </div>

          <div className=" p-5">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                onSubmit(e.target);
              }}
            >
              <div className=" md:flex block justify-between">
                <div className=" md:w-[45%] w-[100%] flex gap-3  flex-col">
                  {/* <div className=" w-full">
                    <label className=" text-lg font-semibold">
                      Deposit Date
                    </label>
                    <Datepicker
                      useRange={false}
                      asSingle={true}
                      value={value}
                      onChange={handleValueChange}
                      inputClassName={" bg-lightGray  rounded-md p-4 w-full"}
                    />
                  </div> */}

                  {/* <div className=" w-full">
                    <Input
                      label={"CNIC No"}

                      placeholder={""}
                      name={"cnic"}
                      // onChange={handleInputs}
                      // value={state.cnic}
                      className={" w-full  py-4"}

                      defaultValue={getData?.cnic}
                    />
                  </div> */}
                  <div className=" w-full">
                    <Input
                      className={' w-full  py-4'}
                      // onChange={(e) => setName(e.target.value)}
                      // value={name}
                      label={'Name'}
                      placeholder={''}
                      name={'name'}
                      // disabled={isEnable}

                      defaultValue={getData?.name}
                    />
                  </div>

                  <div className=" w-full">
                    <label
                      htmlFor="first_name"
                      className=" block mb-2 text-[18px]  font-medium text-black"
                    >
                      Type
                    </label>
                    <select
                      // onChange={handleInputs}
                      name="depositType"
                      // value={state?.depositType}
                      className=" py-4 font-medium w-full rounded border-r-8   bg-lightGray outline-none  border-transparent px-4 text-md"
                    >
                      {/* <option>Select Type of Deposit</option> */}
                      <option value={'cash'}>Cash</option>
                      <option value={'online'}>Online</option>
                    </select>
                  </div>

                  <div className=" w-full">
                    <Input
                      label={'Receipt No'}
                      // onChange={handleInputs}
                      // value={state.receiptNumber}
                      placeholder={''}
                      name={'receiptNumber'}
                      className={' w-full  py-4'}
                      defaultValue={getData?.receiptNumber}
                    />
                  </div>
                  <div className=" w-full">
                    <Input
                      Icon={'PKR'}
                      label={'Profile Amount'}
                      placeholder={''}
                      name={'amount'}
                      type="number"
                      // onChange={handleInputs}
                      // value={state.amount}
                      className={' w-full  py-4'}
                      defaultValue={getData?.amount}
                    />
                  </div>
                  <div className=" w-full">
                    <Input
                      label={'Bank Name'}
                      placeholder={''}
                      name={'bankName'}
                      className={' w-full  py-4'}
                      // onChange={handleInputs}
                      // value={state.bankName}
                      defaultValue={getData?.bankName}
                    />
                  </div>
                  <div className=" w-full">
                    <Input
                      // onChange={handleInputs}
                      // value={state.branchName}
                      label={'Branch'}
                      placeholder={''}
                      name={'branchName'}
                      className={' w-full  py-4'}
                      defaultValue={getData?.branchName}
                    />
                  </div>
                </div>
                <div className=" flex flex-col gap-3 md:w-[40%] w-full">
                  {/* <div className=" w-full">
                    <Input
                      label={"Bank Name"}
                      placeholder={""}
                      name={"bankName"}
                      className={" w-full  py-4"}
                      // onChange={handleInputs}
                      // value={state.bankName}
                      defaultValue={getData?.bankName}
                    />
                  </div>
                  <div className=" w-full">
                    <Input
                      // onChange={handleInputs}
                      // value={state.branchName}
                      label={"Branch"}
                      placeholder={""}
                      name={"branchName"}
                      className={" w-full  py-4"}
                      defaultValue={getData?.branchName}
                    />
                  </div> */}

                  <div className=" w-full">
                    <Input
                      // onChange={handleInputs}
                      // value={state.remark}
                      label={'Remarks'}
                      placeholder={''}
                      name={'remark'}
                      className={' w-full  py-4'}
                      defaultValue={getData?.remark}
                    />
                  </div>
                  <div className=" w-full">
                    <Input
                      // onChange={handleInputs}
                      // value={state.reference}
                      label={'Reference'}
                      placeholder={''}
                      name={'reference'}
                      className={' w-full  py-4'}
                      defaultValue={getData?.reference}
                    />
                  </div>

                  <div
                    className="w-full border-dashed border-[#9E9E9E] border-2 rounded-lg p-4 cursor-pointer"
                    onDragOver={handleDragOver}
                    onDrop={handleDrop}
                    onClick={triggerFileInput} // Allow clicking anywhere in the div to trigger file input
                  >
                    <div className="bg-[#F6F6F6] mx-auto w-14 flex justify-center items-center h-14 rounded-2xl">
                      <MdOutlineFileUpload size={30} color="#c8c7cf" />
                    </div>
                    <p className="text-primary font-medium mt-3 text-center">
                      <span className="text-secondary">Click here</span> or drag and drop to upload
                      a slip
                    </p>
                    {!selectedImage ? (
                      <img
                        src={getData?.slip}
                        alt="Uploaded slip"
                        className="mt-4 mx-auto"
                        style={{ maxWidth: '100%', height: 'auto' }}
                      />
                    ) : (
                      selectedImage && (
                        <img
                          src={selectedImage}
                          alt="Uploaded slip"
                          className="mt-4 mx-auto"
                          style={{ maxWidth: '100%', height: 'auto' }}
                        />
                      )
                    )}

                    <input
                      type="file"
                      onChange={handleFileChange}
                      style={{ display: 'none' }}
                      ref={fileInputRef}
                    />
                  </div>

                  {loading === true ? (
                    <button
                      disabled
                      type="button"
                      className="bg-blue text-center mt-3 justify-center uppercase text-white py-2.5 border-b-black  border-2 w-full"
                    >
                      <svg
                        aria-hidden="true"
                        role="status"
                        className="inline w-4 h-4 me-3 text-white animate-spin"
                        viewBox="0 0 100 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                          fill="#E5E7EB"
                        />
                        <path
                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                          fill="currentColor"
                        />
                      </svg>
                      Loading...
                    </button>
                  ) : (
                    <Button
                      label={'Update'}
                      type={'submit'}
                      className={
                        '     bg-blue text-center mt-3 justify-center uppercase text-white py-2.5 border-b-black  border-2 w-full'
                      }
                    />
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Edit_collection;
